<template>
<div>
  <v-container>
    <v-row>
      <v-col>
          <Api/>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import Api from '../../components/Admin/Api-data'

export default{
  components:{
    Api
  }
}
</script>


