<template>
<div>
  <v-container>
    <v-row>
      <v-col>
          <Order/>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import Order from '../../components/User/Order-user'

export default{
  components:{
    Order
  }
}
</script>


