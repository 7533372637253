<template>
<div>
  <v-container>
  <v-row>
    <v-col>
      <v-card
      class="overflow-hidden"
      color=""
    >
      <v-toolbar
        flat
        color="orange lighten-2"
      >
        <v-icon>mdi-account</v-icon>
        <v-toolbar-title class="font-weight-light">
          Data User
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small @click="addUserDialog = true, add = data">
          <v-icon color="white" class="ma-1" mdi-account-plus></v-icon>
          Tambah User
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
            small
            outlined
            v-model="search"
            dense
            @keyup.enter="searchUser(search)"
            label="Pencarian User"
            placeholder="Nama, Email, Fullname"
            append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
        <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                  Nama
                </th>
                <th class="text-left">
                  Username
                </th>
                <th class="text-left">
                  Email
                </th>
                <th class="text-left">
                  No.Telpon
                </th>
                <th class="text-left">
                  Role
                </th>
                <th class="text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr
                v-for="data,index in data.data"
                :key="index"
              >
                <td><span v-if="data.greeting">{{data.greeting}}</span> {{data.fullname}}</td>
                <td>{{data.name}}</td>
                <td>{{data.email}}</td>
                <td>{{data.phone}}</td>
                <td>                
                  <span v-for="role,index in data.roles" :key="index">
                    <span>
                        {{role.name}}
                    </span>
                  </span>
                </td>


                <td>      
                    <v-icon color="blue"
                      class="ma-1" 
                      @click="dialog =true,edit=data"
                      >
                      mdi-pencil
                    </v-icon> 
                   <!--  <v-icon color="red"
                      class="ma-1" 
                      @click="deleteUser(data)"
                      >
                      mdi-delete
                    </v-icon>  -->
                </td>
              </tr>
            </tbody>
        </v-simple-table>
            <v-divider></v-divider>
              <div >
                <v-pagination
                  v-model="data.current_page"
                  :length="data.last_page"
                  @input="getUser(data)"
                ></v-pagination>
              </div>
    </v-card>
    </v-col>
  <div class="text-center">
      <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 orange lighten-2">
          Edit User "{{edit.name}}"
        </v-card-title>

      <v-card-text class="pa-3">
        <v-alert
          type="error"
          v-for="[error] in errors" :key="error"
        >
        {{error}}
        </v-alert>
        <v-radio-group row v-model="edit.greeting" >
          <v-radio
          label="Kak"
          value="Kak"
          ></v-radio>
          <v-radio
          label="Pak"
          value="Pak"
          ></v-radio>
          <v-radio
          label="Bu"
          value="Bu"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          small
          outlined
          v-model="edit.fullname"
          label="Nama Lengkap"
        ></v-text-field>
        <v-text-field
          small
          outlined
          v-model="edit.name"
          label="Username"
        ></v-text-field>
        <v-text-field
          small
          outlined
          v-model="edit.email"
          label="Email"
        ></v-text-field>
        <v-text-field
          small
          outlined
          type="number"
          v-model="edit.phone"
          label="No.Telpon"
        ></v-text-field>
        <v-select
          small
          :items="roles"
          item-text="name"
          item-value="id"
          label="Pilih role ..."
          v-model="edit.role"
          outlined
        ></v-select>
        
      </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            small
            @click="dialog=false"
          >
            tutup
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            @click="editUser(edit)"
          >
            simpan data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog untuk menambahkan user -->
    <v-dialog v-model="addUserDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 orange lighten-2">
          Tambah User
        </v-card-title>

        <v-card-text class="pa-5 white">
      <div>
        <v-radio-group row v-model="add.greeting" label="Sapaan :">
          <v-radio label="Kak" value="Kak"></v-radio>
          <v-radio label="Pak" value="Pak"></v-radio>
          <v-radio label="Bu" value="Bu"></v-radio>
        </v-radio-group>
        <v-alert
          dense
          type="error"
          v-for="error in errors.greeting"
          :key="error"
        >
          {{ error }}
        </v-alert>
      </div>
      <div>
        <v-text-field
          dense
          outlined
          solo
          v-model="add.fullname"
          label="Nama lengkap"
        ></v-text-field>
        <v-alert
          dense
          type="error"
          v-for="error in errors.fullname"
          :key="error"
        >
          {{ error }}
        </v-alert>
      </div>
      <div>
        <v-text-field
          dense
          outlined
          solo
          v-model="add.name"
          label="Username"
        ></v-text-field>
        <v-alert
          dense
          type="error"
          v-for="error in errors.name"
          :key="error"
        >
          {{ error }}
        </v-alert>
      </div>
      <div>
        <v-text-field
          dense
          outlined
          solo
          type="number"
          v-model="add.phone"
          label="Nomor Whatsapp..."
        ></v-text-field>
        <v-alert
          dense
          type="error"
          v-for="error in errors.phone"
          :key="error"
        >
          {{ error }}
        </v-alert>
      </div>
      <div>
        <v-text-field
          dense
          outlined
          solo
          v-model="add.email"
          label="Email"
        ></v-text-field>
        <v-alert
          dense
          type="error"
          v-for="error in errors.email"
          :key="error"
        >
          {{ error }}
        </v-alert>
      </div>
      <div>
        <v-text-field
          dense
          outlined
          solo
          type="password"
          v-model="add.password"
          label="Password"
        ></v-text-field>
        <v-alert
          dense
          type="error"
          v-for="error in errors.password"
          :key="error"
        >
          {{ error }}
        </v-alert>
      </div>
      <div>
        <v-text-field
          dense
          outlined
          type="password"
          solo
          v-model="add.rePassword"
          label="Ulangi Password"
        ></v-text-field>
        <v-alert
          dense
          type="error"
          v-for="error in errors.rePassword"
          :key="error"
        >
          {{ error }}
        </v-alert>
      </div>
    </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" small @click="addUserDialog = false">Batal</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="createUser(add)">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </v-row>
  </v-container>
  
</div>
</template>
<script>
import axios from 'axios'

  export default {
    data () {
      return {
        success: false,
        model: null,
        dialog: false,
        edit: false,
        roles:{},
        search:'',
        addUserDialog: false,
        add: false
      }
    },
    computed:{
      data(){
        return this.$store.state.user.user.data;        
      },
      errors(){
        return this.$store.state.user.user.error;
      },
      form(){
        return this.$store.state.user.user.form;
      },
      loading(){
        return this.$store.state.user.loading;
      },
    },
    methods: {
      async searchUser(data){
        await this.$store.dispatch('searchUser',data);
      },
      async getUser(data){
        await this.$store.dispatch('user',data.current_page);
      },
      async getRole(){
            try{
                let response = await axios.get('/api/role',{headers: {'Authorization': 'Bearer '+localStorage.getItem('token')}})
                if (response.status == 200) {
                    this.roles = response.data.role
                }
            }catch(errors){
              console.log(errors)
            }
      },
      async createUser(data){
        await this.$store.dispatch('addTambah',data)
        await this.getUser(this.data.current_page)
        this.addUserDialog = false;
      },
      async editUser(data){
        await this.$store.dispatch('editUser',data);
        await this.getUser(this.data.current_page)
        this.dialog = false;
      },
      async deleteUser(data){
        if (confirm('yakin untuk menghapus user ?')) {
          await this.$store.dispatch('deleteUser',data);
          await this.getUser(this.data.current_page)
        }
      }
    },
    mounted() {
      this.getUser(1)
      this.getRole()

    }
  }
</script>
