<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <Html/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Html from "../../components/Admin/Html-data";

export default {
  components: {
    Html,
  },
};
</script>
