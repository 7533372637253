<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="12">
      <Licence/>   
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Licence from '../../components/Admin/Licence-data'

export default {
  components:{
    Licence,
  },
  data(){
    return{

    }
  },
  computed:{

  },
  methods:{

  }
}
</script>
