<template>
<div>
  <v-container>
    <v-row>
      <v-col>
          <Cart/>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import Cart from '../../components/User/Cart-user'

export default{
  components:{
    Cart
  }
}
</script>


