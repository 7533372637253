<template>
<div>
  <v-container>
    <v-row>
      <v-col>
          <Channel/>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import Channel from '../../components/Admin/Channel-data'

export default{
  components:{
    Channel
  }
}
</script>


