<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="9">
          <v-card class="overflow-hidden" color="">
            <v-toolbar flat color="orange lighten-2">
              <v-icon>mdi-card</v-icon>
              <v-toolbar-title class="font-weight-light">
                Data Domain
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="handleDownload">Export to Excel</v-btn> <!-- Button for exporting to Excel -->
            </v-toolbar>
            <v-card-text> </v-card-text>
            <v-divider></v-divider>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">
                    Domain
                    <v-icon @click="sortBy('domain')">mdi-arrow-up-down</v-icon>
                  </th>
                  <th class="text-left">
                    Product
                    <v-icon @click="sortBy('productName')">mdi-arrow-up-down</v-icon>
                  </th>
                  <th class="text-left">
                    User
                    <v-icon @click="sortBy('userName')">mdi-arrow-up-down</v-icon>
                  </th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr
                  v-for="(data, index) in allDomains"
                  :key="index"
                  @click="(domain = data.domain), (domain.licence = data.licence)"
                >
                  <td>{{ data.domain }}</td>
                  <td>{{ data.productName }} ({{ data.productCode }})</td>
                  <td>{{ data.userName }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-divider></v-divider>
            <div>
              <v-pagination
                v-model="data.current_page"
                :length="data.last_page"
                @input="getLicence(data)"
              ></v-pagination>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      allDomains: [],
      product: {},
      user: {},
      domain: false,
      sortByColumn: '',
      sortDesc: false,
    };
  },
  computed: {
    data() {
      return this.$store.state.licence.licence.data;
    },
    errors() {
      return this.$store.state.licence.licence.error;
    },
    form() {
      return this.$store.state.licence.licence.form;
    },
    loading() {
      return this.$store.state.licence.loading;
    },
    expandedData() {
      const expanded = [];
      this.data.data.forEach(item => {
        if (item.domain && item.domain.length > 0) {
          item.domain.forEach(domain => {
            expanded.push({
              ...item,
              domain: domain.domain,
            });
          });
        }
      });
      return expanded;
    },
    sortedData() {
      let sorted = [...this.expandedData];
      if (this.sortByColumn) {
        sorted = sorted.sort((a, b) => {
          const valueA = a[this.sortByColumn];
          const valueB = b[this.sortByColumn];
  
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return this.sortDesc ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
          } else {
            return this.sortDesc ? valueB - valueA : valueA - valueB;
          }
        });
      }
      return sorted;
    },
  },
  methods: {
    async getLicence(data) {
      console.log(data);
      await this.$store.dispatch("licence", data.current_page);
    },
    async getProduct() {
      try {
        let response = await axios.get("/api/product?all=true");
        if (response.status == 200) {
          this.product = response.data.product;
        }
      } catch (errors) {
        this.errors = errors.response.data.erorrs;
      }
    },
    async getUser() {
      try {
        let response = await axios.get("/api/user?all=true", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        if (response.status == 200) {
          this.user = response.data.user;
        }
      } catch (errors) {
        console.log(errors);
      }
    },
    sortBy(column) {
      if (this.sortByColumn === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortByColumn = column;
        this.sortDesc = false;
      }

      // Menerapkan sorting pada data yang ditampilkan
      this.allDomains.sort((a, b) => {
        const valueA = a[column];
        const valueB = b[column];

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return this.sortDesc ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
        } else {
          return this.sortDesc ? valueB - valueA : valueA - valueB;
        }
      });
    },
    async allData() {
      try {
        let response = await axios.get('/api/licence');
        if (response.status == 200) {
          const last_page = response.data.licence.last_page

          let data = [];
          for (let i = 1; i <= last_page; i++) {
            let response = await axios.get('/api/licence?page='+i, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } });
            data = data.concat(response.data.licence.data);
          }

          // Membuat satu array tunggal dari semua data domain, product name, dan user name
          const allDomains = [];
          data.forEach(item => {
            if (item.domain && item.domain.length > 0) {
              item.domain.forEach(domain => {
                allDomains.push({
                  domain: domain.domain,
                  productName: item.product.name,
                  productCode: item.product.code,
                  userName: item.user.name
                });
              });
            }
          });

          this.allDomains = allDomains;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    // Fungsi untuk mengekspor data ke Excel
    handleDownload() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Domain",
          "Produk",
          "User",
        ];
        const filterVal = [
          "domain",
          "productName",
          "userName",
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "domain",
        });
      });
    },
    formatJson(filterVal) {
      return this.allDomains.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
  mounted() {
    this.getLicence(1);
    this.getProduct();
    this.getUser();
    this.allData();
  },
};
</script>
