"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// https://perpustakaanfisip.herokuapp.com

// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || 'http://localhost:8000';
axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || 'http://localhost:88/lisensi-ruasdigital-laravel/public';
axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token');
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';  
axios.defaults.headers.post['withCredentials'] = 'true';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// axios.defaults.headers.post['xsrfCookieName'] = '__test=36ef558dea3dbc55f8f8cb825d1d68b5';
// axios.defaults.headers.common = {
//   'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
//   'X-Requested-With': 'XMLHttpRequest'
// };

let config = {
  baseURL: process.env.baseURL || process.env.apiUrl || "http://localhost:88/lisensi-ruasdigital-laravel/public",
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
