<template>
<div>
  <v-container>
    <v-row>
      <v-col>
          <Product/>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import Product from '../components/User/Product-user'

export default{
  components:{
    Product
  }
}
</script>


