<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="12">
      <Proruct/>   
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Proruct from '../../components/User/Product-user'

export default {
  components:{
    Proruct,
  },
  data(){
    return{

    }
  },
  computed:{

  },
  methods:{

  }
}
</script>
