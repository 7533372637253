<template>
<div>
  <v-container>
    <v-row>
      <v-col>
          <v-card
          >
            <v-card-title class="text-h5">
              Untuk bantuan teknis silakan hubungi team support kami:
            </v-card-title>

            <v-card-subtitle>
              <p>WA : <a href="https://api.whatsapp.com/send?phone=6281311661479">081311661479</a></p>
              <p>Email : mail@asrofi.com</p>
               </v-card-subtitle>

            <v-card-actions>
            </v-card-actions>
          </v-card>      
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>

export default{
  components:{
    
  }
}
</script>


